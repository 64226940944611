import { Route } from 'react-router-dom'
import { DashboardPage } from 'pages/DashboardPage'
import { PromotionsPage } from 'pages/Promotions/PromotionsPage'
import { CommunicationsPage } from 'pages/Communications/CommunicationsPage'
import { RewardItemsPage } from 'pages/RewardItems/RewardItemsPage'
import { PatronAccountsPage } from 'pages/Patrons/PatronAccountsPage'
import { PromotionsNewPage } from 'pages/Promotions/PromotionsNewPage'
import { RewardItemsNewPage } from 'pages/RewardItems/RewardItemsNewPage'
import { SweepstakesPage } from 'pages/Contests/Sweeptakes/SweepstakesPage'
import { RewardCatalogsPage } from 'pages/RewardCatalogs/RewardCatalogsPage'
import { RewardsProgramPage } from 'pages/RewardsPrograms/RewardsProgramPage'
import { RewardItemsDetailPage } from 'pages/RewardItems/RewardItemsDetailPage'
import { SweepstakesNewPage } from 'pages/Contests/Sweeptakes/SweepstakesNewPage'
import { RewardCatalogsNewPage } from 'pages/RewardCatalogs/RewardCatalogsNewPage'
import { PassportGamesPage } from 'pages/Contests/PassportGames/PassportGamesPage'
import { OrganizationList } from 'pages/ClientManagement/Organization/OrganizationList'
import { LEConnectUsersPage } from 'pages/ClientManagement/LEConnectUsers/LEConnectUsersPage'
import { PromotionDetailsPage } from 'pages/Promotions/PromotionDetailsPage/PromotionDetailsPage'
import { PatronOverviewPage } from 'pages/Patrons/PatronAccountDetails/Overview/PatronOverviewPage'
import { PatronNotesList } from 'pages/Patrons/PatronAccountDetails/Notes/PatronNotesList'
import { CorporateAccountList } from 'pages/ClientManagement/CorporateAccounts/CorporateAccountList'
import { CommunicationsSystemBannerNewPage } from 'pages/Communications/CommunicationsNew/SystemBanner/CommunicationsSystemBannerNewPage'
import { NewLEConnectUserPage } from 'pages/ClientManagement/LEConnectUsersPage/NewLEConnectUserPage'
import { LEConnectUserDetailsPage } from 'pages/ClientManagement/LEConnectUsersPage/LEConnectUserDetailsPage'
import { RewardProgramDetailPage } from 'pages/RewardsPrograms/RewardsProgramsDetails/RewardProgramDetailPage'
import { PatronPointBalancePage } from 'pages/Patrons/PatronAccountDetails/PointBalance/PatronPointBalancePage'
import { RewardsCatalogsDetailPage } from 'pages/RewardCatalogs/RewardCatalogsDetails/RewardCatalogsDetailPage'
import { SweepstakeOverviewPage } from 'pages/Contests/Sweeptakes/SweepstakesDetailsPage/SweepstakeOverviewPage'
import { OrganizationsNewPage } from 'pages/ClientManagement/Organization/OrganizationNewPage/OrganizationsNewPage'
import { PatronCheckInHistoryPage } from 'pages/Patrons/PatronAccountDetails/CheckInHistory/PatronCheckInHistoryPage'
import { RewardProgramSettingsPage } from 'pages/RewardsPrograms/RewardsProgramsDetails/Settings/RewardProgramSettingsPage'
import { LicensedEstablishmentListPage } from 'pages/ClientManagement/LicensedEstablishments/LicensedEstablishmentListPage'
import { OrganizationsDetailPage } from 'pages/ClientManagement/Organization/OrganizationDetailsPage/OrganizationsDetailPage'
import { PatronActivityLogPage } from 'pages/Patrons/PatronAccountDetails/ActivityLog/PatronActivityLogPage'
import { RewardProgramSendRewardPage } from 'pages/RewardsPrograms/RewardsProgramsDetails/SendReward/RewardProgramSendRewardPage'
import { ManageCatalogRewardsPage } from 'pages/RewardCatalogs/RewardCatalogsDetails/ManageCatalogRewards/ManageCatalogRewardsPage'
import { CorporateAccountsNewPage } from 'pages/ClientManagement/CorporateAccounts/CorporateAccountNewPage/CorporateAccountsNewPage'
import { RewardProgramRedemptionsPage } from 'pages/RewardsPrograms/RewardsProgramsDetails/Redemptions/RewardProgramRedemptionsPage'
import { SweepstakeParticipatingLocationsPage } from 'pages/Contests/Sweeptakes/SweepstakesDetailsPage/SweepstakeParticipatingLocationsPage'
import { LEConnectUserDetailRoleAndPermissionPage } from 'pages/ClientManagement/LEConnectUsersPage/LEConnectUserDetailRoleAndPermissionPage'
import { CorporateAccountsDetailPage } from 'pages/ClientManagement/CorporateAccounts/CorporateAccountDetailsPage/CorporateAccountsDetailPage'
import { ManageEnrollLocationsPage } from 'pages/Contests/Sweeptakes/SweepstakesEnrollLocations/ManageEnrollLocationsPage'
import { LicensedEstablishmentNewPage } from 'pages/ClientManagement/LicensedEstablishments/LicensedEstablishmentNewPage/LicensedEstablishmentNewPage'
import { RewardProgramTransactionHistoryPage } from 'pages/RewardsPrograms/RewardsProgramsDetails/TransactionHistory/RewardProgramTransactionHistoryPage'
import { LicensedEstablishmentDetailsPage } from 'pages/ClientManagement/LicensedEstablishments/LicensedEstablishmentDetailsPage/LicensedEstablishmentDetailsPage'
import { LicensedEstablishmentAssignOrganizationPage } from 'pages/ClientManagement/LicensedEstablishments/AssignOrganization/LicensedEstablishmentAssignOrganizationPage'
import { LicensedEstablishmentDetailsRewardsProgramPage } from 'pages/ClientManagement/LicensedEstablishments/LicensedEstablishmentDetailsPage/LicensedEstablishmentDetailsRewardsProgramPage'
import { PassportGameNewPage } from 'pages/Contests/PassportGames/PassportGameNewPage'
import { SweepstakesWinnersPage } from 'pages/Contests/Sweeptakes/SweepstakesWinnersPage/SweepstakesWinnersPage'
import { CorporateAccountAssociatedAccountsPage } from 'pages/ClientManagement/CorporateAccounts/CorporateAccountDetailsPage/CorporateAccountAssociatedAccountsPage/CorporateAccountAssociatedAccountsPage'
import { CorporateAccountJJConnectUsersPage } from 'pages/ClientManagement/CorporateAccounts/CorporateAccountDetailsPage/CorporateAccountJJConnectUsersPage/CorporateAccountJJConnectUsersPage'
import { SweepstakesAdditionalEnrollmentDetailsPage } from 'pages/Contests/Sweeptakes/SweepstakesAdditionalEnrollmentDetails/SweepstakesAdditionalEnrollmentDetailsPage'
import { SweepstakesReviewEnrollmentPage } from 'pages/Contests/Sweeptakes/SweepstakesReviewEnrollment/SweepstakesReviewEnrollmentPage'
import { CommunicationsMessageNewPage } from 'pages/Communications/CommunicationsNew/Message/CommunicationsMessageNewPage'
import { SweepstakeDetailsSettingsPage } from 'pages/Contests/Sweeptakes/SweepstakesDetailsPage/SweepstakeDetailsSettingsPage'
import { WinnerDetailsPage } from 'pages/Contests/Sweeptakes/SweepstakesWinnerDetailsPage/WinnerDetailsPage'
import { SweepstakeLocationDetailPage } from 'pages/Contests/Sweeptakes/SweepstakeLocationDetailPage/SweepstakeLocationDetailPage'
import { SettingsDetailsPage } from 'pages/Settings/SettingsDetailsPage'
import { CommunicationDetailsPage } from 'pages/Communications/CommunicationDetailPage/CommunicationDetailsPage'

const routesProtected = [
  { path: '/Home', element: <DashboardPage /> },
  {
    path: '/ClientManagement/LicensedEstablishments',
    element: <LicensedEstablishmentListPage />,
  },
  {
    path: '/ClientManagement/Organizations',
    element: <OrganizationList />,
  },
  {
    path: '/ClientManagement/CorporateAccounts',
    element: <CorporateAccountList />,
  },
  {
    path: '/ClientManagement/LEConnectUsers',
    element: <LEConnectUsersPage />,
  },
  {
    path: '/ClientManagement/LEConnectUsers/New',
    element: <NewLEConnectUserPage />,
  },
  {
    path: '/ClientManagement/LEConnectUsers/:id',
    element: <LEConnectUserDetailsPage />,
  },
  {
    path: '/ClientManagement/LEConnectUsers/:id/RolesAndPermissions',
    element: <LEConnectUserDetailRoleAndPermissionPage />,
  },
  {
    path: '/LicensedEstablishment/New',
    element: <LicensedEstablishmentNewPage />,
  },
  {
    path: '/LicensedEstablishments/:id',
    element: <LicensedEstablishmentDetailsPage />,
  },
  {
    path: '/LicensedEstablishments/:id/RewardsProgram',
    element: <LicensedEstablishmentDetailsRewardsProgramPage />,
  },
  {
    path: '/LicensedEstablishments/:id/AssignOrganization',
    element: <LicensedEstablishmentAssignOrganizationPage />,
  },
  {
    path: '/CorporateAccounts/:id/:tab',
    element: <CorporateAccountsDetailPage />,
  },
  {
    path: '/CorporateAccounts/New',
    element: <CorporateAccountsNewPage />,
  },
  {
    path: '/CorporateAccounts/:id/CorporateAccountAssociatedAccounts',
    element: <CorporateAccountAssociatedAccountsPage />,
  },
  {
    path: '/CorporateAccounts/:id/CorporateAccountLEUsers',
    element: <CorporateAccountJJConnectUsersPage />,
  },
  {
    path: '/Organizations/New',
    element: <OrganizationsNewPage />,
  },
  {
    path: '/Organization/:id',
    element: <OrganizationsDetailPage />,
  },
  {
    path: '/PatronAccounts',
    element: <PatronAccountsPage />,
  },
  {
    path: '/PatronAccounts/:id/Overview',
    element: <PatronOverviewPage />,
  },
  {
    path: '/PatronAccounts/:id/CheckInHistory',
    element: <PatronCheckInHistoryPage />,
  },
  {
    path: '/PatronAccounts/:id/ActivityLog',
    element: <PatronActivityLogPage />,
  },
  {
    path: '/PatronAccounts/:id/PointBalance',
    element: <PatronPointBalancePage />,
  },
  {
    path: '/PatronAccounts/:id/Notes',
    element: <PatronNotesList />,
  },
  {
    path: '/Rewards/RewardPrograms',
    element: <RewardsProgramPage />,
  },
  {
    path: '/Rewards/RewardPrograms/:id',
    element: <RewardProgramDetailPage />,
  },
  {
    path: '/Rewards/RewardPrograms/:id/SendReward',
    element: <RewardProgramSendRewardPage />,
  },
  {
    path: '/Rewards/RewardPrograms/:id/Settings',
    element: <RewardProgramSettingsPage />,
  },
  {
    path: '/Rewards/RewardPrograms/:id/TransactionHistory',
    element: <RewardProgramTransactionHistoryPage />,
  },
  {
    path: '/Rewards/RewardPrograms/:id/Redemption',
    element: <RewardProgramRedemptionsPage />,
  },
  {
    path: '/Rewards/RewardItems',
    element: <RewardItemsPage />,
  },
  {
    path: '/Rewards/RewardItems/:id',
    element: <RewardItemsDetailPage />,
  },
  {
    path: '/Rewards/RewardCatalogs',
    element: <RewardCatalogsPage />,
  },
  {
    path: '/Rewards/RewardCatalogs/:id',
    element: <RewardsCatalogsDetailPage />,
  },
  {
    path: '/Rewards/RewardCatalogs/:id/ManageCatalogRewards',
    element: <ManageCatalogRewardsPage />,
  },
  {
    path: '/Rewards/RewardCatalogs/New',
    element: <RewardCatalogsNewPage />,
  },
  {
    path: '/Rewards/RewardItems/New',
    element: <RewardItemsNewPage />,
  },
  {
    path: '/Contests/Sweepstakes',
    element: <SweepstakesPage />,
  },
  {
    path: '/Contests/Sweepstakes/:id/Overview',
    element: <SweepstakeOverviewPage />,
  },
  {
    path: '/Contests/Sweepstakes/:id/Settings',
    element: <SweepstakeDetailsSettingsPage />,
  },
  {
    path: '/Contests/Sweepstakes/:id/ParticipatingLocations',
    element: <SweepstakeParticipatingLocationsPage />,
  },
  {
    path: '/Contests/Sweepstakes/:id/Manage-participating-locations',
    element: <ManageEnrollLocationsPage />,
  },
  {
    path: '/Contests/Sweepstakes/:id/AdditionalEnrollmentDetails',
    element: <SweepstakesAdditionalEnrollmentDetailsPage />,
  },
  {
    path: '/Contests/Sweepstakes/:id/ReviewEnrollment',
    element: <SweepstakesReviewEnrollmentPage />,
  },
  {
    path: '/Contests/Sweepstakes/:id/AdditionalEnrollmentDetails',
    element: <SweepstakesAdditionalEnrollmentDetailsPage />,
  },
  {
    path: '/Contests/Sweepstakes/:id/ReviewEnrollment',
    element: <SweepstakesReviewEnrollmentPage />,
  },
  {
    path: '/Contests/Sweepstakes/:id/AdditionalEnrollmentDetails',
    element: <SweepstakesAdditionalEnrollmentDetailsPage />,
  },
  {
    path: '/Contests/Sweepstakes/:id/ReviewEnrollment',
    element: <SweepstakesReviewEnrollmentPage />,
  },
  {
    path: '/Contests/Sweepstakes/:contestId/LicensedEstablishment/:licensedEstablishmentId/ViewLocationEnrollment',
    element: <SweepstakeLocationDetailPage />,
  },
  {
    path: '/Contests/Sweepstakes/:id/Winners',
    element: <SweepstakesWinnersPage />,
  },
  {
    path: '/Contests/Sweepstakes/New',
    element: <SweepstakesNewPage />,
  },
  {
    path: '/Contests/PassportGames',
    element: <PassportGamesPage />,
  },
  {
    path: '/Contests/PassportGames/New',
    element: <PassportGameNewPage />,
  },
  {
    path: '/Winners/:id',
    element: <WinnerDetailsPage />,
  },
  {
    path: '/Promotions',
    element: <PromotionsPage />,
  },
  {
    path: '/Promotions/:id',
    element: <PromotionDetailsPage />,
  },
  {
    path: '/Promotions/New',
    element: <PromotionsNewPage />,
  },
  {
    path: '/Communications',
    element: <CommunicationsPage />,
  },
  {
    path: '/Communications/:id',
    element: <CommunicationDetailsPage />,
  },
  {
    path: '/Communications/SystemBanner/New',
    element: <CommunicationsSystemBannerNewPage />,
  },
  {
    path: '/Communications/Message/New',
    element: <CommunicationsMessageNewPage />,
  },
  {
    path: '/Settings/',
    element: <SettingsDetailsPage />,
  },
]

export const getProtectedRoutes = () =>
  routesProtected.map((route) => (
    <Route path={route.path} key={route.path} element={route.element} />
  ))
