import {
  Box,
  CircularProgress,
  Grid,
  Typography,
  useTheme,
} from '@mui/material'
import { useParams } from 'react-router-dom'
import { isNil } from 'lodash'
import { Page } from 'components/Shared/Page'
import { isIdPresent } from 'utils/typeUtils'
import { CommunicationDetailsHeader } from './CommunicationDetailsHeader'
import { useGetCampaignById } from 'hooks/api/useGetCampaignById'
import { SectionHeader } from 'components/SectionHeader'
import { EditCommunicationDetailsModal } from './EditCommunicationDetailsModal'
import { useState } from 'react'
import { usePutCampaign } from 'hooks/api/usePutCampaign'
import { useQueryClient } from '@tanstack/react-query'
import { useSnackbar } from 'stores/useSnackbar'
import { formatDateTime } from 'utils/util'

export const CommunicationDetailsPage = () => {
  const [isModalOpen, setIsModalOpen] = useState(false)
  const toggleModalIsOpen = () => setIsModalOpen(!isModalOpen)
  const id = Number(useParams().id)
  const getCampaignQuery = useGetCampaignById({ id })
  const theme = useTheme()
  const queryClient = useQueryClient()
  const setMessage = useSnackbar((state) => state.setMessage)

  const putCampaignMutation = usePutCampaign({
    id,
    onSuccess: () => {
      setMessage('Campaign has been updated.', 'success', {
        vertical: 'top',
        horizontal: 'right',
      })
      void queryClient.invalidateQueries({
        queryKey: ['/admin/campaigns/{id}', campaign.id],
      })
      toggleModalIsOpen()
    },
    onError: () => {
      setMessage('Error updating Message', 'error', {
        vertical: 'top',
        horizontal: 'right',
      })
    },
  })

  if (getCampaignQuery.isPending) {
    return <CircularProgress />
  }

  if (getCampaignQuery.isError) {
    return <p>An error occurred.</p>
  }

  const campaign = getCampaignQuery.data

  if (isNil(campaign) || !isIdPresent(campaign)) {
    return <p>An error occurred.</p>
  }

  return (
    <Page header={<CommunicationDetailsHeader message={campaign} />}>
      <>
        <Box className={'pt-4'}>
          <Box className="pt-4">
            <Box>
              <SectionHeader
                title="Communication Details"
                onClickButton={toggleModalIsOpen}
                showButton={campaign.status !== 'Sent'}
              />
              <Box
                border={{ md: `1px solid ${theme.palette.grey[200]}` }}
                borderRadius={'5px'}
                p={{ xs: 0, sm: 3 }}
              >
                <Grid
                  container
                  className="flex flex-row flex-wrap"
                  columnGap={15}
                >
                  <Grid item md={2}>
                    <Typography variant="body2" pb={1} color="text.secondary">
                      Date Scheduled
                    </Typography>
                    <Typography variant="body1" pb={2}>
                      {campaign.deliveryDate
                        ? formatDateTime(campaign.deliveryDate, 'P p')
                        : 'None'}
                    </Typography>
                  </Grid>
                  <Grid item md={8}>
                    <Typography variant="body2" pb={1} color="text.secondary">
                      Subject Line
                    </Typography>
                    <Typography height="auto" variant="body1" pb={2}>
                      {campaign.subject ?? ''}
                    </Typography>
                  </Grid>
                </Grid>

                <Grid
                  container
                  className="flex flex-row flex-wrap"
                  columnGap={15}
                >
                  <Grid item md={8}>
                    <Typography variant="body2" pb={1} color="text.secondary">
                      Message Content
                    </Typography>
                    <Typography height="auto" variant="body1" pb={2}>
                      {campaign.body ?? ''}
                    </Typography>
                  </Grid>
                </Grid>
              </Box>
            </Box>
          </Box>
        </Box>

        <EditCommunicationDetailsModal
          campaign={campaign}
          updateIsActive={putCampaignMutation.isPending}
          isModalOpen={isModalOpen}
          toggleModalIsOpen={toggleModalIsOpen}
          onSubmitCampaign={(campaign) => {
            putCampaignMutation.mutate(campaign)
          }}
        />
      </>
    </Page>
  )
}
